/* .big-button {
  width: 200px;
  height: 50px; 
}
.board {
    width: 80%;
} */

:root {
  --board-width: 80%;
}

.board {
  width: var(--board-width);
}

.big-button {
  width: calc(var(--board-width) / 4);
  height: calc(var(--board-width) / 2);
}

/* Add this to your existing CSS file or create a new one */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.highlight-white {
  box-shadow: inset 0 0 3px 3px yellow;
}

.highlight-black {
  box-shadow: inset 0 0 3px 3px blue;
}

.switch-field {
  display: flex;
  margin-bottom: 36px;
  overflow: hidden;
}

.square-suggested-move {
  background-color: #ff5555;
  /* Choose a color that stands out */
  box-shadow: 0 0 10px #ffdd55;
  /* Optional: Adds a glow effect */
}

@media only screen and (max-width: 600px) {
  .big-button {
      width: 90%;
      /* Adjustments for smaller screens */
  }
  .board {
      width: 90%;
  }
  /* Add more responsive styles */
}

button, input[type='text'] {
  padding: 10px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

button:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

body {
  margin: 0;
  padding: 20px;
  font-family: Arial, sans-serif;
}

#settings, #boardContainer, #controls {
  margin-bottom: 20px;
}
body {
  color: #333;
  line-height: 1.6;
}

h1, h2, h3 {
  margin-bottom: 10px;
}
#controls {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
:root {
  --primary-color: #4CAF50;
  --secondary-color: #FFC107;
  --accent-color: #2196F3;
  --text-color: #333;
}

body {
  background-color: #f4f4f4;
  color: var(--text-color);
}
button:hover {
  background-color: var(--accent-color);
  color: white;
}
.board {
  margin: auto;
  /* Other styles to make the board stand out */
}
input[type='radio'], input[type='checkbox'] {
  margin-right: 5px;
}

#promotionPopup {
  position: fixed; /* Fixed position */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); /* Center the popup */
  background-color: white;
  padding: 20px;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 1000; /* Make sure it's above other elements */
}

.accordion-header {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-content {
  display: block;
  padding: 10px;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 5px 5px;
}

.accordion .triangle {
  transition: transform 0.3s ease-in-out;
}

.accordion.active .triangle {
  transform: rotate(180deg);
}
