:root {
  --board-width: 80%;
}

.board {
  width: var(--board-width);
}

.big-button {
  width: calc(var(--board-width) / 4);
  height: calc(var(--board-width) / 2);
}

.switch {
  width: 60px;
  height: 34px;
  display: inline-block;
  position: relative;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  cursor: pointer;
  background-color: #ccc;
  transition: all .4s;
  position: absolute;
  inset: 0;
}

.slider:before {
  content: "";
  background-color: #fff;
  width: 26px;
  height: 26px;
  transition: all .4s;
  position: absolute;
  bottom: 4px;
  left: 4px;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.highlight-white {
  box-shadow: inset 0 0 3px 3px #ff0;
}

.highlight-black {
  box-shadow: inset 0 0 3px 3px #00f;
}

.switch-field {
  margin-bottom: 36px;
  display: flex;
  overflow: hidden;
}

.square-suggested-move {
  background-color: #f55;
  box-shadow: 0 0 10px #fd5;
}

@media only screen and (width <= 600px) {
  .big-button, .board {
    width: 90%;
  }
}

button, input[type="text"] {
  border: none;
  border-radius: 5px;
  margin: 5px;
  padding: 10px;
  box-shadow: 0 2px 5px #0003;
}

button:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}

body {
  margin: 0;
  padding: 20px;
  font-family: Arial, sans-serif;
}

#settings, #boardContainer, #controls {
  margin-bottom: 20px;
}

body {
  color: #333;
  line-height: 1.6;
}

h1, h2, h3 {
  margin-bottom: 10px;
}

#controls {
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
}

:root {
  --primary-color: #4caf50;
  --secondary-color: #ffc107;
  --accent-color: #2196f3;
  --text-color: #333;
}

body {
  color: var(--text-color);
  background-color: #f4f4f4;
}

button:hover {
  background-color: var(--accent-color);
  color: #fff;
}

.board {
  margin: auto;
}

input[type="radio"], input[type="checkbox"] {
  margin-right: 5px;
}

#promotionPopup {
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px #00000080;
}

.accordion-header {
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  display: flex;
}

.accordion-content {
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 5px 5px;
  padding: 10px;
  display: block;
}

.accordion .triangle {
  transition: transform .3s ease-in-out;
}

.accordion.active .triangle {
  transform: rotate(180deg);
}

/*# sourceMappingURL=index.a97c5e82.css.map */
